import React from "react";
import Seo from "../components/seo";
import ProductLayout from "../containers/ProductLayout";
import { Section } from '../GlobalStyles.jsx';
import { graphql, useStaticQuery } from "gatsby";
import { BG_DARK, BG } from '@etiquette-ui/colors';
import Description from "../sections/Products/Dotell/description";
import SecondSection from "../sections/Products/Dotell/second-section";
import '../globalStyles.css';

const Dotell = () => {
  const dataquery = useStaticQuery(query);
  const datainfo = dataquery.allFile.edges;

  const data = {
    currentPage: 4,
    title: 'Dotell',
    heroLogo: datainfo[0].node.childrenImageSharp[0].fluid,
    heroAltLogo: datainfo[0].node.base,
    heroImage: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAlt: datainfo[3].node.base,
    heroMobile: datainfo[3].node.childrenImageSharp[0].fluid,
    heroAltMobile: datainfo[3].node.base,
    banerImg: datainfo[4].node.childrenImageSharp[0].fluid,
    banerAlt: datainfo[4].node.base,
    banerImgMobile: datainfo[4].node.childrenImageSharp[0].fluid,
    banerImgAltMobile: datainfo[4].node.base,
    features: [
      {
        en: 'Apply surveys to your customers in a simple way',
        es: 'Aplica encuestas a tus clientes de una manera fácil y rápida'
      },
      {
        en: 'Obtain data to follow up on customer requests',
        es: 'Obtén información para dar seguimiento a las peticiones de tus clientes'
      },
      {
        en: 'Gain valuable insight into the experience your customers had',
        es: 'Obtén información valiosa sobre la experiencia que tuvieron tus clientes'
      },
      {
        en: 'Consolidate the information to be sent to the Dashboard',
        es: 'Unifica la información que se envía a Dashboard'
      },
    ],
  };

  return (
    <ProductLayout data={data} >
      <Seo title={data.title} />

      <Section color={BG}>
        <Description location={data.title} />
      </Section>
      <Section color={BG_DARK}>
        <SecondSection />
      </Section>
    </ProductLayout >
  );
};
export default Dotell;

const query = graphql`
query dotell {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "dotell-hero",
          "dotell-icon",
          "dotell-banner",
          "digitalmenu-hero-mobile",
          "digitalmenu-banner-mobile"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }  
`;