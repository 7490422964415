import React from 'react'
import Highlight from '../../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import { ThreeColumns, Container } from '../../../GlobalStyles';

import useI18n from '../../../i18n/useI18n';

const SecondSection = () => {

    const { translateObject } = useI18n();
    const highlightTitle = {
        en: 'To rate',
        es: 'Para evaluar'
    }    
    const title = {
        en: 'your team, your food, drinks and more',
        es: 'a tu equipo, alimentos, bebidas y más'
    }
    
    return (
        <Container>
            <ThreeColumns>
                <div className='col-1'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/dotell-2.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="dotell-2"
                        className="img-container"
                        imgClassName="img-fluid"
                    />
                </div>

                <div className='center'data-sal="slide-up" data-sal-easing="ease-in-circ">
                    <h2>
                        <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}                    
                    </h2>
                    <p>
                        {translateObject({
                            en: `How good is your food? Is your staff giving the best possible service? 
                            Find out what your customers are saying about these topics and more through an easy-to-use customer survey.`,
                            es: `¿Qué tan buena es tu comida?, ¿Tu equipo está dando lo mejor de sí?
                            Descubre los que tus clientes están diciendo sobre esto y más a través de estas encuestas que son fáciles de usar.`
                        })}
                    </p>
                </div>

                <div className='col-3'data-sal='slide-left' data-sal-easing="ease-in-circ" data-sal-duration='700'>
                    <StaticImage
                        src="../../../images/dotell-3.png"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="dotell-3"
                        className='img-container'
                        imgClassName='overflowed-img'
                    />
                </div>
            </ThreeColumns>
        </Container>
    )
};

export default SecondSection;